<script lang="ts" setup>
import type { PropType } from 'vue'

import SigningSpinner from '@/components/SigningSpinner.vue'

import OverlayBase from './OverlayBase.vue'

type FlowType = 'swisscom' | 'atrust' | null

const props = defineProps({
  channel: String as PropType<SignChannel>,
  continuationData: Object as PropType<{ url?: string }>,
})

const { t } = useI18n()

const userStore = useUserStore()

const mobileNumber = computed(() => userStore.attributes?.mobile?.[0] ?? '')

const flowType = computed<FlowType>(() => {
  if (props.channel === 'sc_ra' || props.channel === 'sc_fast_track') {
    return 'swisscom'
  } else if (props.channel === 'atrust') {
    return 'atrust'
  }
  return null
})

const dialogData = computed(() => {
  const data = {
    title: '',
    text: '',
  }

  if (props.channel === 'sc_ra') {
    data.title = t('signing_overlay.popup_info.sc_ra.title') as string
    data.text = t('signing_overlay.popup_info.sc_ra.text') as string
  } else if (props.channel === 'sc_fast_track') {
    data.title = t('signing_overlay.popup_info.sc_fast_track.title') as string
    data.text = t('signing_overlay.popup_info.sc_fast_track.text') as string
  }

  return data
})

const continuationUrl = computed(() => {
  return props.continuationData?.url ?? ''
})

const closeDialog = ref(false)

const dialogState = computed(() => Boolean(continuationUrl.value) && !closeDialog.value)

const continueWithSwisscom = () => {
  closeDialog.value = true
  window.open(continuationUrl.value, 'otp', 'alwaysRaised=yes,height=550,width=690')
}
</script>

<template>
  <overlay-base>
    <v-container class="overlay__body" :class="{ 'fill-height': $vuetify.display.mdAndUp }">
      <v-row align="center" justify="center">
        <v-col>
          <div class="dialog--sc__spinner">
            <signing-spinner />
          </div>
          <div v-if="flowType === 'swisscom'" class="dialog--sc__fade">
            <!-- Show info about phone for Swisscom case -->
            <div class="dialog--sc__title">&nbsp;{{ t('signing_overlay.title') }}&nbsp;</div>
            <p class="text-center mt-2">
              {{
                t('signing_overlay.text', {
                  n: `+${mobileNumber}`,
                })
              }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <template v-if="flowType === 'swisscom'">
      <v-dialog v-model="dialogState" persistent max-width="470">
        <v-card>
          <v-card-title class="text-h5">
            {{ dialogData.title }}
          </v-card-title>
          <v-card-text>
            {{ dialogData.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn size="x-large" color="info" variant="outlined" @click="continueWithSwisscom">
              {{ t('signing_overlay.popup_info.cta') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else-if="flowType === 'atrust'">
      <v-dialog v-model="dialogState" persistent max-width="450">
        <iframe class="dialog--atrust" width="450px" height="450px" :src="continuationUrl" scrolling="no" />
      </v-dialog>
    </template>
  </overlay-base>
</template>

<style scoped lang="sass">

$ns: dialog

.#{$ns}
  &--sc
    &__spinner
      display: flex
      justify-content: center
      margin: (10 * $bw) 0

    &__title
      font-size: 1.5rem
      font-weight: bold
      color: $c-text-alt
      text-align: center

    &__fade
      opacity: 0
      animation: .38s fade-in 0s normal forwards

  &--atrust
    border: 0
    padding-top: 15px
</style>
